import { useLocation } from 'react-router-dom'

export const useRouting = (): { path: string | null } => {
  const location = useLocation()

  const pathName = location.pathname

  if (pathName != null && pathName !== '/' && pathName !== '') {
    console.info('setting routingPath', pathName)

    localStorage.setItem('routingPath', pathName)
  }

  return {
    path: localStorage.getItem('routingPath')
  }
}
