import { appInitialized } from '@mit/aws-react'
import { useGetDepartmentsQuery, useGetSponsoredAccountsQuery, useGetAuthorizationQuery } from 'api/SponsoredAccountApi'
import { useGetFeaturesQuery } from 'api/FeatureApi'

import { useEffect, useState } from 'react'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)

  /**
   * Use Skip Option to not query api on initial load. Once state flag is flipped to true, queries will load.
   * Use useEffect to trigger when everything you need has loaded and any auth check has passed/not passed
   *
   * Below is just an example. The profile component handles fetching it's own data
   */
  // const { data: profile } = useGetProfileQuery(undefined, {
  //   skip: !initializeApi
  // })

  // useEffect(() => {
  //   console.log('PROFILE FROM INIT')
  //   if (profile?.krb_name != null) {
  //     appInitialized({
  //       isLoading: false,
  //       hasAccess: true,
  //       hasError: false
  //     })
  //   }
  // }, [profile])

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false
    })
  }, [])

  const { data: auths } = useGetAuthorizationQuery(undefined, {
    skip: !initializeApi
  })

  const { data: features } = useGetFeaturesQuery(undefined, {
    skip: !initializeApi
  })

  const { data: departments } = useGetDepartmentsQuery(undefined, {
    skip: !initializeApi
  })

  const { data: guests } = useGetSponsoredAccountsQuery(undefined, {
    skip: !initializeApi
  })

  useEffect(() => {
    if (auths !== undefined && features !== undefined) {
      appInitialized({
        isLoading: false,
        hasAccess: auths.canCreateGuest || auths.canCreateSpouse || (features.includes('pilot') ?? false),
        hasError: false
      })
    }
  }, [auths, features, departments, guests])

  const initializeApp = async (): Promise<void> => {
    setInitializeApi(true)
  }

  return { initializeApp }
}
