import authService from '../services/AuthService'
import apiConfig from '../api/api-config'

export const prepareHeaders = async (headers: Headers): Promise<Headers> => {
  const token: string = await authService.getToken()

  headers.append('Authorization', `Bearer ${token}`)
  return headers
}

export const getApiConfigKey = (): keyof typeof apiConfig => {
  const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
  const configKey = isGlobalEnabled ? 'global' : 'regional'
  return configKey
}

export const getApiBaseUrl = (): string => {
  const configKey = getApiConfigKey()

  const apiHost = apiConfig[configKey].apiHost ?? ''

  return apiHost
}
