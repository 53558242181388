const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPathDigitalId": "digital-id-v1",
      "apiPathFinance": "finance-v3",
      "apiPathAccount": "account-v2",
      "apiPathFeature": "feature-v1"
    }
  },
  "global": {
    "apiHost": "https://sponsored-accounts.mit.edu/api",
    "paths": {
      "apiPathDigitalId": "digital-id/v1",
      "apiPathFinance": "finance/v3",
      "apiPathAccount": "account/v2",
      "apiPathFeature": "feature/v1"
    }
  }
};
export default apiConfigs;