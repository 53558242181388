import { DigitalIdApi } from './DigitalIdApi'
import { FeatureApi } from './FeatureApi'
import { FinanceApi } from './FinanceApi'
import { SponsoredAccountApi } from './SponsoredAccountApi'

export const apiReducers = {
  [DigitalIdApi.reducerPath]: DigitalIdApi.reducer,
  [FinanceApi.reducerPath]: FinanceApi.reducer,
  [SponsoredAccountApi.reducerPath]: SponsoredAccountApi.reducer,
  [FeatureApi.reducerPath]: FeatureApi.reducer
}

export const apiMiddleware = [DigitalIdApi.middleware, FinanceApi.middleware, SponsoredAccountApi.middleware, FeatureApi.middleware]
