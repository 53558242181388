import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AlertState {
  showAlert: boolean
  title?: string
  message?: string
}

const initialState: AlertState = {
  showAlert: false
}

export const alertSlice = createSlice({
  name: 'showAlert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertState>) => {
      state.showAlert = action.payload.showAlert
      state.title = action.payload.title
      state.message = action.payload.message
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAlert } = alertSlice.actions

export default alertSlice.reducer
